import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { SidebarService } from '../core/services/sidebar.service';
import { LoginService } from '../core/services/login.service';
import { SubscriptionTypes } from '../core/models/subscription-types.model';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { Resources } from 'src/app/core/models/resource.model'
import { VgApiService } from '@videogular/ngx-videogular/core'
import { faBookOpenReader, faCircleInfo, faDollarSign, faGears, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { HttpClient } from '@angular/common/http' ;
import { environment } from '../../environments/environment' ;
import { ResourcesService } from '../core/services/resources.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerDialogComponent } from './pdf-viewer/pdf-viewer-dialog.component';


@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
})
export class ResourcesComponent implements OnInit {
  drawerIsOpen: boolean = false;
  openDrawer: boolean = this.sidebarService.toggleDrawer();
  showGoBack: boolean = true ;
  isKnowledgeBase: boolean = false ;
  freeProduct: string = SubscriptionTypes.FreeProduct;
  fullProduct: string = SubscriptionTypes.FullProduct;
  diagnosticProduct: string = SubscriptionTypes.DiagnosticProduct;
  selectedProduct: string | null = '';
  categoryToView: string | null = this.sessionStorage.getResourceCategory();
  selectedCategory: string | null = '';
  selectedGroup: string | null = '' ;
  showSubCategoryDropdown: boolean = false;
  showLessonFilters: boolean = false;
  subCategories: string[] = [];
  grades: string[] = [];
  units: string[] = [];
  zones: string[] = [];
  numDays: string[] = [];
  selectedGrade: string = '';
  selectedUnit: string = '';
  selectedZone: string = '';
  selectedNumDays: string = '';
  reverseSort: boolean = false;
  orderByField: string = '';
  allResources: Resources[] = [];
  resourceList: Resources[] = [];
  playerAPI: VgApiService;
  videoToLoad = null;
  videoTracks = [{}];
  videoQueue = [];
  viewResourceVideo: boolean = false;
  // TODO: just set one at a time?
  // videoSources: any = [];
  videoSource: string = '';
  backIcon = faRotateLeft ;
  resourcesIcon = faBookOpenReader ;
  gradeUnitParams: { [key: string]: { display: string, units: number[] } } = {} ;
  zoneDayParams: { [key: string]: number[] } = {} ;
  infoIcon = faCircleInfo ;
  gearIcon = faGears ;
  resourceTitle = this.selectedProduct ;

  CURRICULUM_GUIDES: string = "Curriculum Guides";
  ELEMENTARYG1G2_CURRICULUM_GUIDES: string = "Curriculum Guides for Grades 1 & 2";
  ELEMENTARYG3G4_CURRICULUM_GUIDES: string = "Curriculum Guides for Grades 3 & 4";
  SECONDARY_CURRICULUM_GUIDES: string = "Curriculum Guides for Grades 5 and up";
  SECONDARY_CURRICULUMPACKETS: string = "Curriculum Packets for Grade 5+";
  SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION: string = "Curriculum Packets for Grade 5+ - Student Version";
  SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION: string = "Curriculum Packets for Grade 5+ - Teacher Version";
  PBLG: string = "Project-Based Learning Guides"
  SECONDARY_PBLG: string = "Project-Based Learning Guides for Grade 5+";
  POEMS_PASSAGES: string = "Poems & Passages";
  ELEMENTARYG1G2_POEMS_PASSAGES: string = "Poems & Passages for Grades 1 & 2";
  ELEMENTARYG3G4_POEMS_PASSAGES: string = "Poems & Passages for Grades 3 & 4";
  SECONDARY_POEMS_PASSAGES: string = "Poems & Passages for Grades 5 and up";
  SCOPE_SEQUENCE: string = "Scope and Sequence";
  USER_GUIDE: string = "User Guides";
  STUDENT_GOAL_SETTING: string = "Student Goal Setting";
  LESSON: string = "Lesson";
  TECHNICAL_REPORT: string = "Technical Report";
  LESSONS_PACKET: string = "Lesson Packet";
  OVERVIEW: string = "Overview & Introduction";
  ENGAGE: string = "Engag, Read, Discuss";
  DECODING: string = "Decoding";
  SPELLING_DEV: string = "Spelling Development";
  SYLLABLE_FOCUS: string = "Syllable Focus";
  VIDEOS: string= "videos" ;
  MLV: string = 'model-lessons' ;

  constructor(
    private loginService: LoginService,
    private sidebarService: SidebarService,
    private router: Router,
    private route: ActivatedRoute,
    private sessionStorage: SessionStorageService,
    private resourcesService: ResourcesService,
    private location: Location,
    public vgApiService: VgApiService,
    private httpClient: HttpClient,
    private modalService: NgbModal
  ) {
    this.playerAPI = vgApiService;
  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.selectedProduct = this.route.snapshot.paramMap.get('product') ;
    this.selectedGroup = this.route.snapshot.paramMap.get('group') ;
    this.selectedCategory = this.route.snapshot.paramMap.get('category') ;
    this.sessionStorage.setResourceCategory(this.selectedCategory || '') ;

    // Retrieve our available lessons if we are viewing lessons
    if (this.selectedProduct?.toLowerCase() === 'generator')
    {
      this.gradeUnitParams['2'] = { display: '2', units: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24] } ;
      this.gradeUnitParams['3-4'] = { display: '3-4', units: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24] };
      this.gradeUnitParams['5'] = { display: '5+', units: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24] };
      /*
      this.resourcesService.getAvailableLessons().subscribe({
        next: (data) => {
          Object.keys(data).forEach((grade: string) => {
            const gradeNumStart = parseInt(grade.split('-')[0]) ;
            if (this.selectedGroup?.toLowerCase() === 'elementary' && gradeNumStart < 5)
            {
              this.gradeUnitParams[grade] = data[grade] ;
            }
            else if (this.selectedGroup?.toLowerCase() === 'secondary' && gradeNumStart > 4)
            {
              this.gradeUnitParams[grade] = data[grade] ;
            }
          })
        }
      }) ;
       */

      this.zoneDayParams['All'] = [ 0 ] ;
      this.zoneDayParams['Zone 1'] = [ 2, 3, 4, 5 ] ;
      this.zoneDayParams['Zone 2'] = [ 2, 3, 4, 5 ] ;
      this.zoneDayParams['Zone 3'] = [ 2, 3 ] ;
      this.zoneDayParams['Zone 4'] = [ 2, 3 ] ;
    }

    if (this.selectedCategory === this.CURRICULUM_GUIDES)
    {
      this.subCategories = [this.CURRICULUM_GUIDES];
      this.subCategories.push(this.ELEMENTARYG1G2_CURRICULUM_GUIDES, this.ELEMENTARYG3G4_CURRICULUM_GUIDES, this.SECONDARY_CURRICULUM_GUIDES);

      this.showSubCategoryDropdown = true;
    }
    else if (this.selectedCategory === this.SECONDARY_CURRICULUMPACKETS)
    {
      this.resourceTitle = 'Instructional Tools: Grades 5+: Curriculum Packets' ;
      this.subCategories = [this.SECONDARY_CURRICULUMPACKETS];
      this.subCategories.push(this.SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, this.SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION);

      this.showSubCategoryDropdown = true;
    }
    else if (this.selectedCategory === this.POEMS_PASSAGES && this.selectedGroup?.toLowerCase() === 'elementary')
    {
      this.resourceTitle = 'Instructional Tools: Grades 2-4: Poems & Passages' ;
      this.subCategories = [this.POEMS_PASSAGES];
      this.subCategories.push(this.ELEMENTARYG1G2_POEMS_PASSAGES);
      this.subCategories.push(this.ELEMENTARYG3G4_POEMS_PASSAGES);

      this.showSubCategoryDropdown = true;
    }
    else if (this.selectedCategory === this.POEMS_PASSAGES && this.selectedGroup?.toLowerCase() === 'secondary')
    {
      this.resourceTitle = 'Instructional Tools: Grades 5+: Poems & Passages' ;
    }
    else if (this.selectedCategory === this.SECONDARY_PBLG)
    {
      this.resourceTitle = 'Instructional Tools: Grades 5+: Project-Based Learning Guides' ;
    }
    else if (this.selectedCategory === this.USER_GUIDE)
    {
      this.resourceTitle = 'Knowledge Base: User Guides' ;
      this.showGoBack = false ;
      this.isKnowledgeBase = true ;
    }
    else if (this.selectedCategory === this.MLV)
    {
      this.resourceTitle = 'Instructional Tools: Small Group Lessons' ;
      this.showGoBack = false ;
      this.isKnowledgeBase = true ;
    }
    else if (this.selectedCategory === this.STUDENT_GOAL_SETTING)
    {
      this.resourceTitle = 'Knowledge Base: Student Goal Setting' ;
      this.showGoBack = false ;
      this.isKnowledgeBase = true ;
    }

    if (this.sessionStorage.getUserData()?.isParentUser())
    {
      this.orderByField = 'grade';
      this.reverseSort = false;

      /////////////////////////// LIST OF RESOURCES /////////////////////////////////////////
      this.allResources = [];
    }
    else if (this.selectedProduct?.toLowerCase() === 'generator')
    {
      this.resourceTitle = 'Instructional Tools: Create Lesson Plan' ;
      this.showLessonFilters = true;
      this.showGoBack = false ;
    }
    else if (this.selectedProduct == this.fullProduct.toLowerCase() && this.selectedCategory?.toLowerCase() === 'model-lessons')
    {
      console.log('hi') ;
      this.allResources = []  ;
      [
        { link: 'videos/1-MLV-Intro.mp4', icon: '/assets/images/knowledge-base/VideoThm_1-MLV-Intro.png', title: 'The Introduction, All Grades' },
        { link: 'videos/2-MLV-ConnectedTextFiction.mp4', icon: '/assets/images/knowledge-base/VideoThm_2-MLV-ConnectedTextFiction.png', title: 'Reading connected sentences in fiction passages, Grades 5+' },
        { link: 'videos/3-MLV-ConnectedTextNonFiction.mp4', icon: '/assets/images/knowledge-base/VideoThm_3-MLV-ConnectedTextNonfiction.png', title: 'Reading connected sentences in nonfiction passages, Grades 5+' },
        { link: 'videos/4-MLV-DecodingPictures.mp4', icon: '/assets/images/knowledge-base/VideoThm_4-MLV-DecodingPictures.png', title: 'Decoding words, from meaning in pictures to parts of words, Grades 5+' },
        { link: 'videos/5-MLV-DecodingSnapIt.mp4', icon: '/assets/images/knowledge-base/VideoThm_5-MLV-DecodingSnapIt.png', title: 'Decoding words, using rimes to make new words, Grades 3-4' },
        { link: 'videos/6-MLV-EngageReadDiscuss.mp4', icon: '/assets/images/knowledge-base/VideoThm_6-MLV-EngageReadDiscuss.png', title: 'Engaging, reading, and discussing short passages or poems, Grade 2' },
        { link: 'videos/7-MLV-PhrasingSillyPhrases.mp4', icon: '/assets/images/knowledge-base/VideoThm_7-MLV-PhrasingSillyPhrases.png', title: 'Reading Phrases -- Making them silly! Grades 5+' },
        { link: 'videos/8-MLV-SpellingWhatIsMissing.mp4', icon: '/assets/images/knowledge-base/VideoThm_8-MLV-SpellingWhatIsMissing.png', title: 'Finding what is missing in a word -- Reinforcing spelling concepts, Grades 3-4' },
        { link: 'videos/9-MLV-SpellingSpellingTheWord.mp4', icon: '/assets/images/knowledge-base/VideoThm_9-MLV-SpellingSpellingTheWord.png', title: 'Spelling the word, Grades 3-4' },
        { link: 'videos/10-MLV-SyllableFocusCTM.mp4', icon: '/assets/images/knowledge-base/VideoThm_10-MLV-SyllableFocusCTM.png', title: 'Adding Prefixes and Suffixes to Change the Meaning of Words, Grades 3-4' },
        { link: 'videos/11-MLV-SyllableFocusSyllables.mp4', icon: '/assets/images/knowledge-base/VideoThm_11-MLV-SyllableFocusSyllables.png', title: 'Finding the number of syllables inside a word, Grades 3-4' },
        { link: 'videos/HowtoCreateaLessonPlan.mp4', icon: '/assets/images/knowledge-base/VideoThm_HowToCreateALessonPlan.png', title: 'How to Create a Lesson Plan' },
        { link: 'videos/OverviewofSmallGroupInstruction.mp4', icon: '/assets/images/knowledge-base/VideoThm_OverviewOfSmallGroupInstruction.png', title: 'Overview of Small Group Instruction' },
      ].forEach((modelLesson) => {
        this.allResources.push(new Resources({
          grade: '',
          category: this.MLV,
          isVideo: true,
          link: modelLesson.link,
          icon: modelLesson.icon,
          title: modelLesson.title,
          product: this.fullProduct,
        }));
      })
    }
    else if (this.selectedProduct == this.fullProduct.toLowerCase())
    {
      this.orderByField = 'grade';
      this.reverseSort = false;

      /////////////////////////// LIST OF RESOURCES /////////////////////////////////////////
      this.allResources = [];

      //Poems and Passages All
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_AllUnits-PoemsPassages_Student_Gr1-2.pdf", icon: "pdf.png", title: "Poems and Passages Student Version Grades 1-2 All Units", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit2.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 2, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit3.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 3, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit4.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 4, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit5.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 5, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit6.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 6, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit7.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 7, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit8.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 8, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit9.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 9, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit10.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 10, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit11.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 11, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit12.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 12, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit13.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 13, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit14.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 14, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit15.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 15, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit16.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 16, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit17.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 17, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit18.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 18, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit19.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 19, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit20.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 20, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit21.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 21, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit22.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 22, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit23.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 23, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr1-2_Unit24.pdf", icon: "pdf.png", title: "Poems and Passages Grades 1-2 Unit 24, Student and Teacher Versions", product: this.fullProduct}));

      //Poems and Passages Gr 3-4
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit1.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 1, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit2.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 2, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit3.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 3, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit4.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 4, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit5.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 5, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit6.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 6, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit7.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 7, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit8.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 8, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit9.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 9, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit10.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 10, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit11.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 11, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit12.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 12, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit13.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 13, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit14.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 14, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit15.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 15, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit16.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 16, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit17.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 17, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit18.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 18, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit19.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 19, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit20.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 20, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit21.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 21, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit22.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 22, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit23.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 23, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG3G4_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr3-4_Unit24.pdf", icon: "pdf.png", title: "Poems and Passages Grades 3-4 Unit 24, Student and Teacher Versions", product: this.fullProduct}));

      //Poems and Passages MS
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit1.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 1, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit2.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 2, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit3.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 3, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit4.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 4, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit5.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 5, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit6.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 6, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit7.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 7, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit8.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 8, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit9.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 9, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit10.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 10, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit11.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 11, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit12.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 12, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit13.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 13, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit14.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 14, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit15.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 15, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit16.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 16, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit17.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 17, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit18.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 18, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit19.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 19, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit20.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 20, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit21.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 21, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit22.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 22, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit23.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 23, Student and Teacher Versions", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_POEMS_PASSAGES, link: "WordFlight_PoemPassages_Gr5Above_Unit24.pdf", icon: "pdf.png", title: "Poems and Passages Grades 5 & Above Unit 24, Student and Teacher Versions", product: this.fullProduct}));

      //EL G1 G2 Curriculum Guides
      this.allResources.push(new Resources({grade: "EL", category: this.ELEMENTARYG1G2_CURRICULUM_GUIDES, link: "WordFlight_SchemaForDailyLessonPlans_Gr1-2.pdf", icon: "pdf.png", title: "Schema for Daily Lesson Plans", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_SyllableTypesAndVowelPronunciation.pdf", icon: "pdf.png", title: "Syllable Types and Vowel Pronunciation", product: this.fullProduct}))
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit1.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 1", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit2.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 2", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit3.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 3", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit4.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 4", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit5.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 5", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit6.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 6", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit7.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 7", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit8.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 8", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit9.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 9", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit10.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 10", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit11.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 11", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit12.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 12", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit13.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 13", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit14.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 14", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit15.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 15", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit16.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 16", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit17.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 17", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit18.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 18", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit19.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 19", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit20.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 20", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit21.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 21", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit22.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 22", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit23.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 23", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG1G2_CURRICULUM_GUIDES, link:  "WordFlight_InstructionalTeacherGuide_Gr1-2_Unit24.pdf", icon:  "pdf.png", title: "Instructional Passages and Activities Unit 24", product: this.fullProduct}));

      //EL G1 G2 Curriculum Guides
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_SchemaForDailyLessonPlans_Gr3-4.pdf", icon: "pdf.png", title: "Schema for Daily Lesson Plans", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_SyllableTypesAndVowelPronunciation.pdf", icon: "pdf.png", title: "Syllable Types and Vowel Pronunciation", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit1.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 1", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit2.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 2", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit3.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 3", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit4.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 4", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit5.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 5", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit6.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 6", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit7.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 7", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit8.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 8", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit9.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 9", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit10.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 10", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit11.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 11", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit12.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 12", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit13.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 13", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit14.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 14", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit15.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 15", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit16.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 16", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit17.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 17", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit18.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 18", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit19.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 19", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit20.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 20", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit21.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 21", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit22.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 22", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit23.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 23", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "EL", category: this. ELEMENTARYG3G4_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr3-4_Unit24.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 24", product: this.fullProduct}));

      //MS Curriculum Guides
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_SchemaForDailyLessonPlans_Gr5Above.pdf", icon: "pdf.png", title: "Schema for Daily Lesson Plans", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_SyllableTypesAndVowelPronunciation.pdf", icon: "pdf.png", title: "Syllable Types and Vowel Pronunciation", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit1.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 1", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit2.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 2", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit3.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 3", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit4.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 4", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit5.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 5", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit6.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 6", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit7.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 7", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit8.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 8", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit9.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 9", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit10.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 10", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit11.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 11", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit12.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 12", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit13.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 13", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit14.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 14", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit15.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 15", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit16.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 16", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit17.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 17", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit18.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 18", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit19.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 19", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit20.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 20", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit21.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 21", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit22.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 22", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit23.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 23", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUM_GUIDES, link: "WordFlight_InstructionalTeacherGuide_Gr5Above_Unit24.pdf", icon: "pdf.png", title: "Instructional Passages and Activities Unit 24", product: this.fullProduct}));

      //Curriculum Packets - Teacher Version
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "Placement-into-Small-Group.pdf", icon: "pdf.png", title: "Placement into Small Group", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "Guide-for-Using-Instructional-Curriculum-Packets.pdf", icon: "pdf.png", title: "Guide for Using Instructional Curriculum Packets", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "Syllable-Types-and-Vowel-Pronunciation.pdf", icon: "pdf.png", title: "Syllable Types and Vowel Pronunciation", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit1.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 1", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit2.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 2", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit3.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 3", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit4.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 4", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit5.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 5", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit6.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 6", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit7.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 7", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit8.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 8", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit9.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 9", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit10.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 10", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit11.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 11", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit12.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 12", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit13.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 13", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit14.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 14", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit15.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 15", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit16.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 16", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit17.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 17", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit18.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 18", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit19.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 19", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit20.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 20", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit21.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 21", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit22.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 22", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit23.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 23", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_TEACHER_VERSION, link: "WordFlight_CurriculumPacket-Teacher-Unit24.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 24", product: this.fullProduct}));

      //Curriculum Packets - Student Version
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit1.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 1", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit2.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 2", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit3.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 3", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit4.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 4", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit5.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 5", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit6.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 6", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit7.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 7", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit8.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 8", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit9.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 9", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit10.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 10", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit11.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 11", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit12.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 12", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit13.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 13", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit14.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 14", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit15.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 15", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit16.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 16", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit17.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 17", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit18.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 18", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit19.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 19", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit20.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 20", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit21.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 21", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit22.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 22", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit23.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 23", product: this.fullProduct}));
      this.allResources.push(new Resources({grade: "MS", category: this. SECONDARY_CURRICULUMPACKETS_STUDENT_VERSION, link: "WordFlight_CurriculumPacket-Student-Unit24.pdf", icon: "pdf.png", title: "Instructional Curriculum Packet Unit 24", product: this.fullProduct}));

      //Project-Based Learning Guides
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearning-IntroLetter.pdf", icon: "pdf.png", title: "Project-Based Learning Introduction Letter", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearning-Rubrics.pdf", icon: "pdf.png", title: "Project-Based Learning Rubrics", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit1.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 1", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit2.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 2", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit3.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 3", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit4.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 4", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit5.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 5", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit6.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 6", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit7.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 7", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit8.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 8", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit9.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 9", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit10.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 10", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit11.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 11", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit12.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 12", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit13.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 13", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit14.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 14", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit15.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 15", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit16.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 16", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit17.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 17", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit18.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 18", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit19.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 19", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit20.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 20", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit21.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 21", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit22.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 22", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit23.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 23", product: this.fullProduct}));
      this.allResources.push(new Resources({ grade: "MS", category: this.SECONDARY_PBLG, link: "FLS_ProjectBasedLearningActivity-Unit24.pdf", icon: "pdf.png", title: "Project-Based Learning Guide Unit 24", product: this.fullProduct}));

      //MISC Category

      this.allResources.push(new Resources({
        grade: "",
        category: this.USER_GUIDE,
        link: "WordFlight_TG-IntroAndInstructionalFramework-EL.pdf",
        icon: "/assets/images/knowledge-base/UserGuidesThm_IntroAndInstructionalFrameworkEL.png",
        title: "Elementary Student Introduction",
        description: "Elementary student instructions for getting started with WordFlight",
        product: this.fullProduct
      }))

      this.allResources.push(new Resources({
        grade: "",
        category: this.USER_GUIDE,
        link: "WordFlight_TG-IntroAndInstructionalFramework-SEC.pdf",
        icon: "/assets/images/knowledge-base/UserGuidesThm_IntroAndInstructionalFrameworkSEC.png",
        title: "Secondary Student Introduction",
        description: "Secondary student instructions for getting started with WordFlight",
        product: this.fullProduct
      }))

      this.allResources.push(new Resources({
        grade: "",
        category: this.USER_GUIDE,
        link: "WordFlight_TG-DemonstrationUserGuide.pdf",
        icon: "/assets/images/knowledge-base/UserGuidesThm_TeacherDemo.png",
        title: "Teacher Demo",
        description: "A guide to the demonstration version of the online student program",
        product: this.fullProduct
      }));

      this.allResources.push(new Resources({
        grade: "",
        category: this.USER_GUIDE,
        link: "WordFlight_TG-StudentExperienceUserGuide.pdf",
        icon: "/assets/images/knowledge-base/UserGuidesThm_StudentExperience.png",
        title: "Student Experience",
        description: "Student instructions for getting started with WordFlight",
        product: this.fullProduct
      }));

      this.allResources.push(new Resources({
        grade: "",
        category: this.USER_GUIDE,
        link: "WordFlight_TG-TeacherManagement.pdf",
        icon: "/assets/images/knowledge-base/UserGuidesThm_TeacherManagement.png",
        title: "Teacher Management",
        description: "A guide for getting started with WordFlight",
        product: this.fullProduct
      }));

      this.allResources.push(new Resources({
        grade: "",
        category: this.USER_GUIDE,
        link: "WordFlight_ScopeAndSequence.pdf",
        icon: "/assets/images/knowledge-base/UserGuidesThm_ScopeAndSequence.png",
        title: "Scope and Sequence",
        description: "",
        product: this.fullProduct
      }));

      this.allResources.push(new Resources({
        grade: "",
        category: this.USER_GUIDE,
        link: "WordFlight_ReportsQuickGuide.pdf",
        icon: "/assets/images/knowledge-base/UserGuidesThm_ReportsQuickGuide.png",
        title: "Reports Quick Guide",
        description: "A quick guide for getting started with WordFlight reports",
        product: this.fullProduct
      }));

      this.allResources.push(new Resources({
        grade: "",
        category: this.USER_GUIDE,
        link: "WordFlight_QuickStartGuide-StudentEnrollment.pdf",
        icon: "/assets/images/knowledge-base/UserGuidesThm_QuickStartStudentEnrollment.png",
        title: "Quick Start - Student Enrollment",
        description: "A quick guide for enrolling students in WordFlight",
        product: this.fullProduct
      }));

      this.allResources.push(new Resources({
        grade: "",
        category: this.USER_GUIDE,
        link: "WordFlight_QuickStartGuide-MovingStudents.pdf",
        icon: "/assets/images/knowledge-base/UserGuidesThm_QuickStartMoveToSystem.png",
        title: "Quick Start - Moving Students",
        description: "A quick guide for moving students to the WordFlight System",
        product: this.fullProduct
      }));

      this.allResources.push(new Resources({
        grade: "",
        category: this.USER_GUIDE,
        link: "WordFlight_QuickStartGuide-DiagnosticSchedule.pdf",
        icon: "/assets/images/knowledge-base/UserGuidesThm_QuickStartChangeDiagnostic.png",
        title: "Quick Start - Diagnostic Schedule",
        description: "A quick guide for changing the Diagnostic Schedule",
        product: this.fullProduct
      }));

      this.allResources.push(new Resources({
        grade: "",
        category: this.STUDENT_GOAL_SETTING,
        isPdf: true,
        link: "WordFlight_GoalSetting-StudentMonitoringTools-OptionsforGoalSetting.pdf",
        icon: "/assets/images/knowledge-base/StudentGoalSettingThm_01-OptionsGoalSetting.png",
        title: "Student Monitoring Tools - Options for Goal Setting",
        product: this.freeProduct
      }));
      this.allResources.push(new Resources({
        grade: "",
        category: this.STUDENT_GOAL_SETTING,
        isPdf: true,
        link: "WordFlight_GoalSetting-IndividualStudentLevelTracking.pdf",
        icon: "/assets/images/knowledge-base/StudentGoalSettingThm_02-LevelTracking.png",
        title: "Individual Student Level Tracking",
        product: this.freeProduct
      }));
      this.allResources.push(new Resources({
        grade: "",
        category: this.STUDENT_GOAL_SETTING,
        isPdf: true,
        link: "WordFlight_GoalSetting-IndividualStudentWeeklyMin-Bar.pdf",
        icon: "/assets/images/knowledge-base/StudentGoalSettingThm_03-WeeklyMinBar.png",
        title: "Individual Student Weekly Minutes - Bar Graph",
        product: this.freeProduct
      }));
      this.allResources.push(new Resources({
        grade: "",
        category: this.STUDENT_GOAL_SETTING,
        isPdf: true,
        link: "WordFlight_GoalSetting-IndividualStudentWeeklyMin60-Line.pdf",
        icon: "/assets/images/knowledge-base/StudentGoalSettingThm_04-WeeklyMinLine60.png",
        title: "Individual Student Weekly 60 Minutes - Line Graph",
        product: this.freeProduct
      }));
      this.allResources.push(new Resources({
        grade: "",
        category: this.STUDENT_GOAL_SETTING,
        isPdf: true,
        link: "WordFlight_GoalSetting-IndividualStudentWeeklyMin80-Line.pdf",
        icon: "/assets/images/knowledge-base/StudentGoalSettingThm_05-WeeklyMinLine80.png",
        title: "Individual Student Weekly 80 Minutes - Line Graph",
        product: this.freeProduct
      }));
      this.allResources.push(new Resources({
        grade: "",
        category: this.STUDENT_GOAL_SETTING,
        isPdf: true,
        link: "WordFlight_GoalSetting-MultiStudentTrackers.pdf",
        icon: "/assets/images/knowledge-base/StudentGoalSettingThm_06-MultiUnitCompletion.png",
        title: "Multi Student Trackers",
        product: this.freeProduct
      }));
      this.allResources.push(new Resources({
        grade: "",
        category: this.STUDENT_GOAL_SETTING,
        isPdf: true,
        link: "WordFlight_GoalSetting-Stickers-Round.pdf",
        icon: "/assets/images/knowledge-base/StudentGoalSettingThm_07-StickersRound.png",
        title: "Stickers - Round",
        product: this.freeProduct
      }));
      this.allResources.push(new Resources({
        grade: "",
        category: this.STUDENT_GOAL_SETTING,
        isPdf: true,
        link: "WordFlight_GoalSetting-Stickers-Rectangle.pdf",
        icon: "/assets/images/knowledge-base/StudentGoalSettingThm_08-StickersRectangle.png",
        title: "Stickers - Rectangle",
        product: this.freeProduct
      }));
      this.allResources.push(new Resources({
        grade: "",
        category: this.STUDENT_GOAL_SETTING,
        isPdf: true,
        link: "WordFlight_StudentCertificate.pdf",
        icon: "/assets/images/knowledge-base/StudentGoalSettingThm_StudentCertificate.png",
        title: "Student Certificate",
        product: this.freeProduct
      }));
    }
    else if (this.selectedProduct == this.freeProduct.toLowerCase())
    {
      this.resourceTitle = 'Knowledge Base: Screener'
      this.orderByField = 'grade';
      this.reverseSort = false;
      this.showGoBack = false ;
      this.isKnowledgeBase = true ;

      // TODO: Video Component
      //   if ($state.params.document && $state.params.document === 'welcome' && $state.params.showIntroVideo)
      // {
      //   this.playVideo('videos/welcome.mp4') ;
      // }

      /////////////////////////// LIST OF RESOURCES /////////////////////////////////////////

      this.allResources = [];
      this.allResources.push(new Resources({
        grade: "",
        category: this.USER_GUIDE,
        isHtml: true,
        link: "welcome",
        icon: "/assets/images/knowledge-base/ScreenerThm_Welcome.png",
        title: "Welcome to the WordFlight Screener",
        product: this.freeProduct,
        resourceUrl: 'html/welcome.html'
      }));
      this.allResources.push(new Resources({
        grade: "",
        category: this.USER_GUIDE,
        isHtml: true,
        link: "faq",
        icon: "/assets/images/knowledge-base/ScreenerThm_FAQs.png",
        title: "Frequently Asked Questions (FAQs)",
        product: this.freeProduct,
        resourceUrl: 'html/faq.html'
      }));
      this.allResources.push(new Resources({
        grade: "",
        category: this.USER_GUIDE,
        isVideo: true,
        link: "videos/welcome.mp4",
        icon: "/assets/images/knowledge-base/ScreenerThm_OverviewVideo.png",
        title: "Overview of the WordFlight Screener",
        product: this.freeProduct
      }));
    }
    else if (this.selectedProduct === this.VIDEOS)
    {
      // This implementation feels like it might be a bit of a hack, double usage of "product" route param
      this.resourceTitle = 'Knowledge Base: Professional Learning' ;
      this.showGoBack = false ;
      this.isKnowledgeBase = true ;

      this.allResources.push(new Resources({
        grade: "",
        category: this.VIDEOS,
        isVideo: true,
        link: "videos/1-1overview.mp4",
        icon: "/assets/images/knowledge-base/VideoThm_1.1-Overview.png",
        title: "WordFlight Overview",
        product: this.freeProduct
      }));
      this.allResources.push(new Resources({
        grade: "",
        category: this.VIDEOS,
        isVideo: true,
        link: "videos/1-2gettingyourstudentsintowordflight.mp4",
        icon: "/assets/images/knowledge-base/VideoThm_1.2-StudentsIntoWordFlight.png",
        title: "Getting Students into WordFlight",
        product: this.freeProduct
      }));
      this.allResources.push(new Resources({
        grade: "",
        category: this.VIDEOS,
        isVideo: true,
        link: "videos/1-3thescreenerbefore.mp4",
        icon: "/assets/images/knowledge-base/VideoThm_1.3-ScreenerBefore.png",
        title: "Screener Before You Begin",
        product: this.freeProduct
      }));
      this.allResources.push(new Resources({
        grade: "",
        category: this.VIDEOS,
        isVideo: true,
        link: "videos/1-4thescreenernextsteps.mp4",
        icon: "/assets/images/knowledge-base/VideoThm_1.4-ScreenerNextSteps.png",
        title: "Screener Next Steps",
        product: this.freeProduct
      }));
      this.allResources.push(new Resources({
        grade: "",
        category: this.VIDEOS,
        isVideo: true,
        link: "videos/1-5motivationalsystems.mp4",
        icon: "/assets/images/knowledge-base/VideoThm_1.5-MotivationalSystems.png",
        title: "Motivational Systems",
        product: this.freeProduct
      }));
      this.allResources.push(new Resources({
        grade: "",
        category: this.VIDEOS,
        isVideo: true,
        link: "videos/1-6managementsite.mp4",
        icon: "/assets/images/knowledge-base/VideoThm_1.6-ManagementSite.png",
        title: "Management Site",
        product: this.freeProduct
      }));
      this.allResources.push(new Resources({
        grade: "",
        category: this.VIDEOS,
        isVideo: true,
        link: "videos/WordFlightStudentProgram.mp4",
        icon: "/assets/images/knowledge-base/VideoThm_WordFlightStudentProgram.png",
        title: "The WordFlight Student Program",
        product: this.freeProduct
      }));
      this.allResources.push(new Resources({
        grade: "",
        category: this.VIDEOS,
        isVideo: true,
        link: "videos/InterpretingtheWordFlightScreener.mp4",
        icon: "/assets/images/knowledge-base/VideoThm_InterpretScreener.png",
        title: "Interpreting the WordFlight Screener",
        product: this.freeProduct
      }));
      this.allResources.push(new Resources({
        grade: "",
        category: this.VIDEOS,
        isVideo: true,
        link: "videos/InterpretingtheWordFlightDiagnostic.mp4",
        icon: "/assets/images/knowledge-base/VideoThm_InterpretDiagnostic.png",
        title: "Interpreting the WordFlight Diagnostic",
        product: this.freeProduct
      }));
      this.allResources.push(new Resources({
        grade: "",
        category: this.VIDEOS,
        isVideo: true,
        link: "videos/IntroductionWordFlightScreener.mp4",
        icon: "/assets/images/knowledge-base/VideoThm_IntroScreener.png",
        title: "Introduction to the WordFlight Screener for Students",
        product: this.freeProduct
      }));
      this.allResources.push(new Resources({
        grade: "",
        category: this.VIDEOS,
        isVideo: true,
        link: "videos/StudentIntroductionWordFlightDiagnostic.mp4",
        icon: "/assets/images/knowledge-base/VideoThm_IntroDiagnostic.png",
        title: "Student Introduction to the WordFlight Diagnostic",
        product: this.freeProduct
      }));
    }
    else
    {
      this.orderByField = 'grade';
      this.reverseSort = false;

      /////////////////////////// LIST OF RESOURCES /////////////////////////////////////////
      this.allResources = [];
      // this.allResources.push(new Resources({grade: "", category: this.TEACHER_GUIDE, link: "Diagnostic-Teacher-Guide.pdf", icon: "pdf.png", title: "Teacher Guide", product: this.diagnosticProduct}));
      this.allResources.push(new Resources({grade: "", category: this.TECHNICAL_REPORT, link: "Diagnostic-Technical-Report.pdf", icon: "pdf.png", title: "Technical Report", product: this.diagnosticProduct}));

    }

    this.filterResults() ;
  }

  // A helper that calls the backend route to fetch the combined lesson
  // pdf based on the provided parameters
  generateLesson(){
    if(this.selectedGrade?.length == 0 ||
      this.selectedUnit?.length == 0 ||
      this.selectedZone?.length == 0 ||
      this.selectedNumDays?.length == 0 ){
        alert('Please provide full selection');
        return;
    }
    const reqOpts = {
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    };

    const body = {
      "grade": this.selectedGrade,
      "unit": this.selectedUnit,
      "zone": this.selectedZone,
      "num-days": this.selectedNumDays,
    };

    if (this.sessionStorage.getUserData()?.isTeacherReviewUser()) {
      const modalRef = this.modalService.open(PdfViewerDialogComponent, { size: 'lg' });
      modalRef.componentInstance.pdfUrl = `${environment.dataServicesURL}manage/lesson/getLessonReview` ;
      modalRef.componentInstance.type = 'lesson' ;
      modalRef.componentInstance.data = body ;

      return ;
    }

    this.httpClient.post(`${environment.dataServicesURL}manage/lesson/getLesson`, body, {...reqOpts, responseType : 'blob'})
      .pipe().subscribe({
        next: (response: any) => {
          // As of now, display the fetched pdf in a new tab
          var fileURL = window.URL.createObjectURL(response);
          let tab = window.open();
          tab!.location.href = fileURL;
        },
        error: (err: any) => {
          if(err.status == 401)
            alert("No content matches the selected parameters");
        }
    });
  }

  sortTable(columnName: string) {
    this.reverseSort = this.orderByField === columnName ? !this.reverseSort : false;
  }

  onPlayerReady(API: VgApiService) {
    this.playerAPI = API;
    this.playerAPI.getDefaultMedia().subscriptions.ended.subscribe({
      next: () => { this.onCompleteVideo(); }
    });
  }

  onCompleteVideo(){
    this.playerAPI.pause();
    this.playerAPI.currentTime = 0;
    this.viewResourceVideo = false;
  }

  setVideo(video: any){
    this.videoSource = "/assets/resources/" + video;
  }

  playVideo(video: any) {
    if (this.playerAPI != null) {
      this.viewResourceVideo = true;
      this.setVideo(video);
      this.playerAPI.play();
    }
  }

  replayVideo(){
    this.playVideo(this.videoToLoad)
  };

  goToLink(resource: any) {
    if (resource.isVideo) {
      this.playVideo(encodeURI(resource.link)) ;
    } else if (this.selectedProduct === this.freeProduct.toLowerCase() && resource.isHtml) {
      // TODO: We really should find a better way to determine if the resource should be viewed in the browser or not (HTML resource)
      this.router.navigateByUrl(`/resources/${this.freeProduct.toLowerCase()}/all/view/${resource.link}`, { state: { resourceUrl: resource.resourceUrl }}) ;
    } else if ((resource.grade == null) || (resource.grade === "")) {
      if (this.sessionStorage.getUserData()?.isTeacherReviewUser()) {
        this.openPdfModal(resource);
      }
      else
      {
        window.open('/assets/resources/' + encodeURIComponent(resource.link), '_blank');
      }
    } else {
      // limit view for Teacher Review users
      if (this.sessionStorage.getUserData()?.isTeacherReviewUser()) {
        this.openPdfModal(resource);
      } else {
        window.open('/assets/resources/' + resource.grade + '/' + encodeURIComponent(resource.category) + '/' + encodeURIComponent(resource.link), '_blank');
      }
    }
  }

  // Go back to category select
  goBack() {
    this.router.navigate(['resources', this.selectedProduct, 'list', this.selectedGroup]) ;
  }

  filterResults() {
    let filteredResources: Resources[];
    let showAllCategories: boolean = (this.selectedCategory == 'All')

    if (showAllCategories && this.selectedProduct === this.fullProduct.toLowerCase() || this.selectedProduct === this.freeProduct.toLowerCase() || this.selectedProduct === this.VIDEOS)
    {
      filteredResources = this.allResources;
    }
    else if (showAllCategories)
    {
      filteredResources = [];
      for (let resourceIndex in this.allResources) {
        let resource = this.allResources[resourceIndex];
        if (resource.product == this.diagnosticProduct)
        {
          filteredResources.push(resource)
        }
      }
    }
    else
    {
      filteredResources = [];
      for (let resourceIndex in this.allResources) {
        let resource = this.allResources[resourceIndex];
        if (resource.category.includes(this.selectedCategory!) &&
          ((this.selectedGroup === 'elementary' && resource.grade === 'EL') || (this.selectedGroup === 'secondary' && resource.grade === 'MS') || (this.selectedGroup === 'all' && resource.grade === ''))) {
          filteredResources = this.addIfInProduct(filteredResources, resource)
        }
      }
    }
    this.resourceList = filteredResources;
  }

  addIfInProduct(filteredResources: any, resource: any) {
    if (this.selectedProduct !== this.fullProduct.toLowerCase() && resource.product === this.diagnosticProduct) {
      filteredResources.push(resource);
    } else if (this.selectedProduct === this.freeProduct.toLowerCase()) {
      filteredResources.push(resource);
    } else if (this.selectedProduct === this.fullProduct.toLowerCase() || resource.product === "") {
      filteredResources.push(resource)
    }

    return filteredResources;

  }

  openPdfModal(resource: any) {
    const modalRef = this.modalService.open(PdfViewerDialogComponent, { size: 'lg' });
    let url = '' ;
    if (resource.category === 'User Guides')
    {
      url = `${environment.dataServicesURL}manage/review/guides/${encodeURIComponent(resource.link)}` ;
    }
    else if (resource.category === this.STUDENT_GOAL_SETTING)
    {
      url = `${environment.dataServicesURL}manage/review/goals/${encodeURIComponent(resource.link)}` ;
    }
    else
    {
      url = `${environment.dataServicesURL}manage/review/resources/${resource.grade}/${encodeURIComponent(resource.category)}/${encodeURIComponent(resource.link)}` ;
    }
    modalRef.componentInstance.pdfUrl = url ;
  }
}
